import React from "react";
import styled from "styled-components";

import TextField from "components/text-field/TextField.component";
import { useLocationState } from "state/LocationProvider";
import { handleChangeText, handleChangeTextMasked } from "helpers/inputChanges";
import { useGlobalState } from "state/ContextProvider";

const FieldsResidencial = () => {
  const {
    numero,
    complemento,
    setNumero,
    setRua,
    setBairro,
    setCep,
    setCidade,
    setComplemento
  } = useLocationState();

  const { leadAddress, cep } = useGlobalState();

  return (
    <>
      <RowStyle>
        <TextField
          onChange={(e) => handleChangeTextMasked(e, setCep, "99999-999")}
          name="cep"
          value={cep.value}
          label="CEP*"
          disabled
          required
          md={48}
        />

        <TextField
          onChange={(e) => handleChangeText(e, setCidade)}
          name="cidade"
          value={leadAddress.cidade || leadAddress.localidade}
          disabled
          label="Cidade*"
          md={48}
          required
        />
      </RowStyle>

      <RowStyle>
        <TextField
          onChange={(e) => handleChangeText(e, setBairro)}
          name="bairro"
          value={leadAddress.bairro}
          label="Bairro*"
          disabled
          required
        />

        <TextField
          onChange={(e) => handleChangeText(e, setRua)}
          name="rua"
          value={leadAddress.endereco}
          label="Rua*"
          disabled
          required
        />

        <TextField
          onChange={(e) => handleChangeText(e, setNumero)}
          name="numero"
          value={numero.value}
          label="Numero*"
          error={numero.error}
          required
          md={48}
        />

        <TextField
          onChange={(e) => handleChangeText(e, setComplemento)}
          name="complemento"
          value={complemento.value}
          label="Complemento"
          error={complemento.error}
          required
          md={48}
        />
      </RowStyle>
    </>
  );
};

const RowStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export default FieldsResidencial;
