import React, { useState, useEffect } from "react";
import styled from "styled-components";
import media from "styled-media-query";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import FadeIn from "react-fade-in";

import { useGlobalState } from "state/ContextProvider";
import { formatBRL } from "helpers/formatCurrency";
import Text from "components/text/Text.component";
import PlanSummaryItem from "components/plan-summary-item/PlanSummaryItem.component";
import { useLocationState } from "state/LocationProvider";
import { finallyLead } from "services/orderSummary";
import PrevNextBox from "components/prev-next-box/PrevNextBox.component";

import LogaModal from "components/loga-modal/LogaModal.component";
import alert from "assets/img/alert.svg";

function OrderSummary() {
  const [terms, setTerms] = useState(false);
  const [toggleModalErr,setToggleModalErr] = useState(false);
  const [errMessage,setErrMessage] = useState({
    title:"",
    message:""
  });


  const {
    combo,
    name,
    email,
    phone,
    uid,
    setProtocolo,
    leadAddress,
    isBusiness,
    supported,
    chipDestiny,
    setMessageResponse
  } = useGlobalState();

  const {
    billingInfo,
    rua,
    bairro,
    numero,
    establishment,
    setInProgress,
    inProgress,
    selectedBuilding,
    newPredio,
    onlyMobile,
    setStep
  } = useLocationState();

  const navigation = useHistory();

  useEffect(() => {
    // if(!name || !combo.length) {
    //   navigation.push('/')
    // }
  }, [name, combo]);

  const handleChangeChecked = (e) => {
    const { checked } = e.target;
    setTerms(checked);
  };

  const getTotal = (items) =>
    items.reduce((prev, curr) => prev + curr.preco, 0);

  const onClickBackStep = () =>{
    const filtered = combo.filter((items)=> items?.adesao > 0 );

      if(filtered.length){
        setStep(4)
      }else{
        setStep(3)
      }
  }

  const onClickContract = async () => {
    let success = false;
    try {
      if (!terms) {
        setToggleModalErr(true);
       return setErrMessage({
        ...errMessage,
        title:"Atenção",
        message:"É preciso aceitar os termos para contratar"
      });
      }

      if (establishment !== "negocio") {
        setInProgress(true);

        const data = await finallyLead(uid, terms);
        setProtocolo(data.protocolo);
      }

      success = true;
    } catch (err) {
      success = false;
      console.log(err.response)
      if(err.response.status === 401){
        setMessageResponse(err?.response?.data?.message)
        return navigation.push("/sem-permissao");
      }
      setErrMessage({
        ...errMessage,
        title:err?.response?.data?.message || "Opss... Ocorreu um erro",
        message:"Ocorreu um erro ao finalizar pedido. Entre em contato com o suporte"
      });

    } finally {
      setInProgress(false);
      success && navigation.push("/protocolo");
    }
  };

  const getFullAddress = () => {
    if (onlyMobile.onlyMobile) {
      return `${chipDestiny.endereco} - ${chipDestiny.numero}, ${chipDestiny.bairro}, ${chipDestiny.cidade} - ${chipDestiny?.uf}`;
    }

    if (establishment === "predial" && !newPredio && selectedBuilding) {
      return `${selectedBuilding.nome}, apt. ${numero.value} - ${
        selectedBuilding.numero
      }, ${selectedBuilding.endereco}, ${selectedBuilding.bairro}, ${
        selectedBuilding.cidade || selectedBuilding.localidade
      }`;
    }

    if (establishment === "predial" && newPredio) {
      return `${rua.value} - ${numero.value}, ${bairro.value}, ${
        leadAddress.localidade || leadAddress.cidade
      }`;
    }

    const complemento = ` ${leadAddress.complemento},`;

    return leadAddress
      ? `${leadAddress.endereco} - ${numero.value},${leadAddress.complemento == '' ? '' : complemento} ${leadAddress.bairro}, ${
          leadAddress.localidade || leadAddress.cidade
        }`
      : "";
  };

  const getDeadline = () => {
    const maxNumber = combo.reduce((prev, curt) => {
      return Math.max(prev, curt.prazo);
    }, 0);

    return supported ? maxNumber : maxNumber + 20;
  };

  const getAccessionItems = (items) => {
    const filtered = items.filter((item) => item?.adesao > 0);

    if (!filtered.length) return null;

    return (
      <AccessionWrapper>
        {filtered.map((item) => (
          <FadeIn key={`access-${item.id}`}>
            <p>
              Será cobrada uma taxa de adesão da {item.nome} no valor de&nbsp;
              <strong>{formatBRL(item.adesao)}</strong> na primeira fatura.
            </p>
          </FadeIn>
        ))}
      </AccessionWrapper>
    );
  };

  const getDateLine = () => {
    if (onlyMobile.onlyMobile) {
      return (
        <DeadlineWrapper>
          <Deadline>Prazo de entrega do Smart Loga</Deadline>
          <p>
            Seu chip Smart Loga será enviado após a confirmação da compra e tem
            um prazo máximo de entrega de
            <strong> 10 dias úteis após o envio</strong>.
          </p>
        </DeadlineWrapper>
      );
    }

    if (onlyMobile.haveMobile) {
      return (
        <>
          <DeadlineWrapper>
            <Deadline>Prazo de entrega do Smart Loga</Deadline>
            <p>
              Seu chip Smart Loga será enviado após a confirmação da compra e
              tem um prazo máximo de entrega de{" "}
              <strong>10 dias úteis após o envio</strong>.
            </p>
          </DeadlineWrapper>

          <DeadlineWrapper>
            <Deadline>Prazo de Instalação</Deadline>
            <p>
              Sua instalação será efetuada em até{" "}
              <strong>{getDeadline()} dias úteis</strong>.
            </p>
            <p>
              Após a confirmação do pedido, nosso time vai entrar em contato
              para agendar o procedimento.
            </p>
            {!supported && (
              <p>
                O seu prédio ainda não está adequado para receber a nossa fibra
                e o processo de adequação tem um prazo de 20 dias úteis para ser
                concluído após a autorização do síndico.
              </p>
            )}
          </DeadlineWrapper>
        </>
      );
    }

    return (
      <DeadlineWrapper>
        <Deadline>Prazo de Instalação</Deadline>
        <p>
          Sua instalação será efetuada em até{" "}
          <strong>{getDeadline()} dias úteis</strong>.
        </p>
        <p>
          Após a confirmação do pedido, nosso time vai entrar em contato para
          agendar o procedimento.
        </p>
        {!supported && (
          <p>
            O seu prédio ainda não está adequado para receber a nossa fibra e o
            processo de adequação tem um prazo de 20 dias úteis para ser
            concluído após a autorização do síndico.
          </p>
        )}
      </DeadlineWrapper>
    );
  };

  const handleToggleErr = () => setToggleModalErr((old) => !old);

  return (
    <>
    <LogaModal toggle={toggleModalErr} handleTogle={handleToggleErr}>
        <Wrapper>
          <Image src={alert} alt="Mensagem de alerta"/>

          <TitleModal>
            {errMessage.title}
          </TitleModal>

          <TextModal>
            {errMessage.message}
          </TextModal>

          <ButtonAccept onClick={handleToggleErr}>Fechar</ButtonAccept>
        </Wrapper>
    </LogaModal>

    <ContainerStyle>
      <SectionStyle>
        <SectionTitleStyle>Meu Plano</SectionTitleStyle>
        {combo.map((plan) => (
          <PlanSummaryItem key={`item-${plan.id}`} plan={plan} showLink />
        ))}

        {/* <SectionTitleStyle>Quer turbinar seu plano?</SectionTitleStyle>

        {staticTurbo.map((item) => (
          <CheckBoxField
            key={item.id}
            name={item.name}
            value={item.id}
            isSelected={turboAds.indexOf(item.id) !== -1}
            onChange={onChangeTurboAds}
          >
            <span>{item.label}</span>&nbsp;<span className="aditional">+ {formatBRL(item.value)}</span>
          </CheckBoxField>
        ))} */}
        {!!combo.length && !combo.some((item) => item.adesao > 0) && (
          <Intalation>Adesão grátis</Intalation>
        )}
        <TotalWrapper>
          <span>Valor do plano</span>
          {/* <span>{formatBRL(selecteds.length >= 4 ? ((getTotal(selecteds) + increment) - 100) : getTotal(selecteds) + increment)}</span> */}
          <span>{formatBRL(getTotal(combo))}</span>
        </TotalWrapper>

        {!!combo.length && getAccessionItems(combo)}

        <TermosWrapperStye>
          <CheckBox
            checked={terms}
            id="termos"
            onChange={handleChangeChecked}
            disabled={inProgress}
          />
          <Terms htmlFor="termos">
            Eu declaro que li e aceito os <strong>Termos de uso</strong> e com
            as Políticas de privacidade de todos os produtos selecionados.,
          </Terms>
        </TermosWrapperStye>

        <ButtonWrapper>
          <PrevNextBox
            overwriteBack={onClickBackStep}
            overwriteNext={onClickContract}
            text="Contratar agora"
          />
        </ButtonWrapper>
      </SectionStyle>

      <SectionStyleTwo>
        <SectionTitleStyle>Dados cadastrais</SectionTitleStyle>

        <Text label="Nome" describe={name.value} />
        {establishment !== "negocio" && (
          <Text
            label={isBusiness ? "CNPJ/CPF" : "CPF"}
            describe={billingInfo.cpf}
          />
        )}
        <Text label="Email" describe={email.value} />
        <Text label="Telefone" describe={phone.value} />
        {establishment !== "negocio" && (
          <>
            {!!rua && (
              <Text
                label={
                  onlyMobile.onlyMobile
                    ? "Endereço de entrega"
                    : "Endereço de instalação"
                }
                describe={getFullAddress()}
              />
            )}
          </>
        )}
        {getDateLine()}
      </SectionStyleTwo>
    </ContainerStyle>
    </>
  );
}

const ContainerStyle = styled.section`
  display: flex;
  margin-top: 1.3rem;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  flex-direction: row;

  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`;

const SectionStyle = styled.section`
  min-width: 300px;
  width: 100%;
  max-width: 470px;
  display: flex;
  flex-direction: column;
  flex: 2;
  order: 1;

  &:last-child {
    flex: 1;
  }

  &:not(:last-child) {
    margin-right: 2rem;
  }

  ${media.lessThan("medium")`
    order: 2;
    min-width: auto;
    max-width: 100%;

    &:not(:last-child) {
      margin-right: 0;
    }

    &:last-child {
      flex: 2;
    }
  `}
`;

const DeadlineWrapper = styled.div`
  background-color: #f6f6f6;
  padding: 1rem;
  border-radius: 12px;
  margin: 1.1rem 0;
  & p {
    font-family: "Nunito";
    font-size: 0.9rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.secondary};
  }

  & p:not(:last-child) {
    margin-bottom: 14px;
  }

  & strong {
    font-weight: bold;
  }
`;

const AccessionWrapper = styled.div`
  align-self: stretch;
  margin: 1rem 0;
  font-family: "Nunito";
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 400;

  & p {
    margin: 14px 0;
    font-size: 0.9rem !important;
    line-height: 1rem;

    & strong {
      font-weight: bold !important;
    }
  }
`;

const Deadline = styled.h4`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.primary};
  font-family: "VAGRounded BT";
  margin-bottom: 10px;
`;

const SectionStyleTwo = styled(SectionStyle)`
  order: 2;

  ${media.lessThan("medium")`
    order: 1;
  `}
`;

const SectionTitleStyle = styled.h3`
  font-size: 1.1rem;
  font-family: "VAGRounded BT";
  margin-bottom: 1.2rem;
  color: ${({ theme }) => theme.colors.primary};
`;

const TotalWrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.input};
  align-self: stretch;
  height: 95px;
  margin-top: 2rem;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 1rem;

  & span:first-child {
    font-size: 1rem;
    text-align: left;
    color: ${({ theme }) => theme.colors.secondary};
    font-family: "VAGRounded BT";
    margin-bottom: 10px;
  }

  & span:last-child {
    font-size: 2rem;
    text-align: left;
    color: ${({ theme }) => theme.colors.compos};
    font-family: "VAGRounded BT";
  }
`;

const TermosWrapperStye = styled.div`
  display: flex;
  align-items: center;
`;

const CheckBox = styled.input.attrs({
  type: "checkbox",
})`
  height: 20px;
  width: 20px;
  border-radius: 6px;
  border: 1px solid #707070;
  margin-right: 0.8rem;

  ${media.lessThan("medium")`
    height: auto;
    width: auto;
  `}
`;

const Terms = styled.label`
  font-size: 0.8rem;
  font-family: "Nunito";
  display: inline-block;
  color: ${({ theme }) => theme.colors.secondary};
  max-width: 500px;
  & strong {
    font-weight: bold;
  }

  ${media.lessThan("medium")`
    font-size: 0.7rem;
  `}
`;

const ButtonWrapper = styled.section`
  margin: 1rem 0;
`;

const Intalation = styled.p`
  color: ${({ theme }) => theme.colors.compos};
  font-family: "VAGRounded BT";
  font-size: 16px;
  text-align: right;
`;

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const Image = styled.img`
  width:70px;
  height:60px;
`;

const TitleModal = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Nunito";
  font-size: 1.625rem;
  font-weight: normal;
  text-align: center;
  margin: 20px;
  margin-bottom: 1.3rem;
  line-height: 1.3;
`;

const TextModal = styled(TitleModal)`
  text-align: center;
  font-size:1.125rem;
  margin-top: 1.5rem;
  font-weight: bold;
  & strong {
    font-weight: bold;
  }
`;

export const ButtonAccept = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  flex: 1;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 11px;
  font-size: 18px;
  padding: 1rem;
  align-self: center;
  width: 226.16px;

  /* &:focus,
  &:hover {
    background-color: #d87e24;
  } */
`;

export default OrderSummary;
