export const server = "";

const dns = window.location.host;

const isDEV = dns.includes("localhost") || dns.includes("homologacao");


function getEndPoint() {
  const kongUrl = isDEV
    ? `https://sgt-homologacao.loga.net.br/api/sgt/v1`
    : `https://sgt.loga.net.br/api/sgt/v1`

  const hashUrl = isDEV
    ? `https://ms-homologacao.loga.net.br`
    : `https://assine.loga.net.br`

  const microservices = {
    attendance: `${hashUrl}/acfc1f4cde`,
    client: `${hashUrl}/5a63897a0f/loga/v1`,
    leads: `${hashUrl}/a6037ef291`,
    connectMaster: `${hashUrl}/d9a4bb8270`,
    plans: `${hashUrl}/68ea410b79`,
    channels: `${hashUrl}/d7a4dt8003`,

    massiva: `${kongUrl}/massives`,
    auth: `${kongUrl}/auth`,
    cep: `${kongUrl}/zipcode`,
    banners: `${kongUrl}/appbanners`,
    renegotiation: `${kongUrl}/manage"`,
    users: `${kongUrl}`,
    invoices: `${kongUrl}/assine/invoices`,
  }

  return microservices
}

export const MICRO_SERVICES = getEndPoint();
