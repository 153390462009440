import React, { useCallback, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import media from "styled-media-query";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import Form from "components/form/Form.component";
import PrevNexBox from "components/prev-next-box/PrevNextBox.component";
import { isEmpty } from "helpers/validateFields";
import { getBuilding } from "services/connectMaster";
import { useLocationState } from "state/LocationProvider";
import { useGlobalState } from "state/ContextProvider";
import { getLocationFromAddress } from "services/geocoding";
import {
  saveInviabialidadePredial,
  savePredialLocation,
} from "services/location";
import SelectSearch from "components/select-search/SelectSearch.component";

import SelectEstablishment from "./SelectEstablishment.component";
import FieldsResidencial from "./FieldsResidencial.component";
import FieldsPredial from "./FieldsPredial.component";
import LogaModal from "components/loga-modal/LogaModal.component";
import alert from "assets/img/alert.svg";

function Establishment() {
  const [builds, setBuilds] = useState([]);

  const {
    numero,
    rua,
    complemento,
    bairro,
    establishment,
    cidade,
    numeroAndares,
    torre,
    ala,
    newPredio,
    nomePredio,
    andar,
    numeroApartamentos,

    setNumero,
    setNomePredio,
    setRua,
    setBairro,
    setEstablishment,
    setNumeroAndares,
    setCoords,
    onClickNextStep,
    setInProgress,
    setNewPredio,
    setAndar,
    selectedBuilding,
    setSelectedBuilding,
    onlyMobile,
    setToggleModal,
  } = useLocationState();

  const {
    uid,
    leadAddress,
    setLeadAddres,
    cep: userCep,
    isBusiness,
    ibge,
    setSupported,
  } = useGlobalState();

  const [toggleModalErr,setToggleModalErr] = useState(false);
  const [errMessage,setErrMessage] = useState({
    title:"",
    message:""
  });

  const navigation = useHistory();

  const findBuildings = useCallback(async () => {
    try {
      setInProgress(true);

      if (!leadAddress || !leadAddress.cidade) {
        setBuilds([]);
        setInProgress(false);
        return;
      }

      const result = await getBuilding(
        leadAddress.localidade || leadAddress.cidade
      );
      setBuilds(result);
    } catch (err) {
      setToggleModalErr(true);
       setErrMessage({
        ...errMessage,
        title:err?.response?.data?.message || err?.response?.data?.error || "Opss... Falha ao obter informações.",
        message:"Falha ao obter informações do prédio."
      });
    } finally {
      setInProgress(false);
    }
  }, []);

  useEffect(() => {
    findBuildings();
    clear([setNumero, setAndar]);
  }, [findBuildings]);

  const clear = (sets) => {
    sets.forEach((set) => {
      set({
        value: "",
        error: "",
      });
    });
  };

  const onChangeEstablishment = (e) => {
    const { value } = e.target;
    setInProgress(false);
    setEstablishment(value);
  };

  const onSelectOption = (option) => {
    setCoords({
      lat: option.lat,
      lon: option.lon,
    });
    setNomePredio({
      value: option.nome,
      error: "",
    });
    setNumero({
      value: "",
      error: "",
    });
    setBairro({
      value: option.bairro,
      error: "",
    });
    setRua({
      value: option.endereco,
      error: "",
    });

    setSelectedBuilding(option);
  };

  const handleClickNext = async (e) => {
    try {
      setInProgress(true);

      if (establishment === "predial" && !newPredio) {
        if (!selectedBuilding) {
          setToggleModalErr(true);
          setErrMessage({
            ...errMessage,
            title: "Atenção",
            message:"Por favor, escolha seu edifício"
          });
          return setInProgress(false);
        }

        const empty = isEmpty([numero, andar], [setNumero, setAndar]);

        if (empty) {
          return setInProgress(false);
        }

        if (numeroAndares.error || numero.error) {
          return setInProgress(false);
        }
      }

      if (establishment === "predial" && newPredio) {
        const empty = isEmpty(
          [rua, numero, numeroAndares, nomePredio],
          [setRua, setNumero, setNumeroAndares, setNomePredio]
        );

        if (empty) {
          return setInProgress(false);
        }

        if (numeroAndares.error || numero.error) {
          return setInProgress(false);
        }
      }

      if (establishment === "residencial") {
        const empty = isEmpty([numero], [setNumero]);

        if (empty) {
          return setInProgress(false);
        }

        setLeadAddres({
          ...leadAddress,
          complemento: complemento.value,
        });
      }

      if (establishment === "negocio") {
        setInProgress(false);
        return onClickNextStep(e);
      }

      await setCoordsByAddess();

      setInProgress(true);

      if (establishment === "predial" && !newPredio && !selectedBuilding) {
        setToggleModalErr(true);
        setErrMessage({
          ...errMessage,
          title:"Opss... Não encontrado.",
          message:"O prédio informado não foi encontrado."
        });
        setInProgress(false);
        return;
      }

      if (establishment === "predial" && !newPredio) {
        const res = await savePredialLocation(
          {
            apartamento: numero.value,
            andar: andar.value,
            torre: torre.value,
            ala: ala.value,
            cep: String(userCep.value).replace(/\D/gi, ""),
            edificio: String(selectedBuilding.id),
            localidade: leadAddress.localidade,
          },
          uid
        );

        if (res.value.data && res.value.data.viabilidade_retorno.status !== 1) {
          setSupported(false);
        }
      }

      if (establishment === "predial" && newPredio) {
        setSupported(false);
        await saveInviabialidadePredial(
          {
            ala: ala.value,
            bairro: leadAddress.bairro,
            numero: numero.value,
            numero_andares: numeroAndares.value,
            numero_apartamentos: numeroApartamentos.value,
            predio: nomePredio.value,
            rua: rua.value,
            torre: torre.value,
            cep: String(userCep.value).replace(/\D/gi, ""),
            ibge,
          },
          uid
        );

        setInProgress(false);

        if (!onlyMobile.haveMobile) {
          return navigation.push("/sem-suporte");
        }

        return setToggleModal(true);
      }

      setInProgress(false);

      onClickNextStep(e);
    } catch (err) {
      setInProgress(false);
      setToggleModalErr(true);
       setErrMessage({
        ...errMessage,
        title:err?.response?.data?.message || "Opss... Falha ao salvar.",
        message:"Falha ao salvar informações."
      });
    }
  };

  const handleClickNotSearch = () => {
    setNewPredio((oldState) => !oldState);
  };

  const setCoordsByAddess = async () => {
    try {
      setInProgress(true);

      let results = [];

      if (establishment === "predial" && !newPredio) {
        results = await getLocationFromAddress(
          `${rua.value}, ${numero.value}, ${bairro.value}, ${cidade.value}`
        );
      }

      if (establishment === "predial" && newPredio) {
        results = await getLocationFromAddress(
          `${rua.value}, ${numero.value}, ${leadAddress.bairro}, ${leadAddress.localidade} - ES, ${userCep.value}, Brazil`
        );
      }

      if (establishment === "residencial") {
        results = await getLocationFromAddress(
          `${leadAddress.endereco}, ${numero.value}, ${leadAddress.bairro}, ${leadAddress.localidade}`
        );
      }

      if (!results.length) throw new Error();

      setCoords({
        lat: results[0].geometry.location.lat,
        lon: results[0].geometry.location.lng,
      });
    } catch (err) {
      setToggleModalErr(true);
       setErrMessage({
        ...errMessage,
        title:err?.response?.data?.message || "Opss... Falha ao salvar.",
        message:"Falha ao salvar informações."
      });

    } finally {
      setInProgress(false);
    }
  };

  const handleToggleErr = () => setToggleModalErr((old) => !old);

  return (
    <>
      <LogaModal toggle={toggleModalErr} handleTogle={handleToggleErr}>
        <Wrapper>
          <Image src={alert} alt="Mensagem de alerta"/>

          <Title>
            {errMessage.title}
          </Title>

          <Text>
            {errMessage.message}
          </Text>

          <ButtonAccept onClick={handleToggleErr}>Fechar</ButtonAccept>
        </Wrapper>
      </LogaModal>

      <EstablishmentWrapper>
        {generateOptions(isBusiness).map((option) => (
          <SelectEstablishment
            key={option.value}
            onChange={onChangeEstablishment}
            value={option.value}
            name="establishment"
            selected={establishment}
            title={option.title}
            popover={option.popover || null}
            id={option.id}
          />
        ))}
      </EstablishmentWrapper>

      <Form onSubmit={(e) => e.preventDefault()}>
        {establishment === "predial" && (
          <>
            <SelectSearch
              name="nomePredio"
              id="nomePredio"
              label="Qual é o seu prédio?*"
              placeholder="Digite o nome do seu prédio..."
              disabled={newPredio}
              value={selectedBuilding}
              options={builds}
              onClickNewPredio={handleClickNotSearch}
              // loadOptions={loadOptions}
              onSelectOption={onSelectOption}
              getOptionLabel={(option) => {
                return `${option.nome}, ${option.numero} - ${option.end}`;
              }}
              getOptionValue={(option) => {
                return option.id;
              }}
            />

            {(selectedBuilding || newPredio) && <FieldsPredial />}
          </>
        )}

        {establishment === "residencial" && <FieldsResidencial />}
      </Form>

      {!!establishment && <PrevNexBox overwriteNext={handleClickNext} />}
    </>
  );
}

const generateOptions = (isBusiness) => [
  {
    value: "predial", // inviabilidade_predial
    title: isBusiness ? "Sala ou Loja Em Prédio Comercial" : "Meu apartamento",
    popover: isBusiness
      ? "Escolha esta opção se sua empresa estiver instalada em uma sala num edifício corporativo ou loja comercial, galeria ou conjunto de lojas de um prédio, podendo ser comercial ou residencial."
      : null,
    id: "pop-predial",
  },
  //{
  //   value: 'residencial',
  //   title: 'Minha casa'
  // },
  {
    value: "residencial",
    title: isBusiness ? "Loja ou Casa comercial em Rua/Avenida" : "Minha casa",
    popover: isBusiness
      ? "Escolha esta opção, se sua empresa estiver instalada em endereços únicos que não sejam prédios ou galerias."
      : null,
    id: "pop-residencial",
  },
];

const EstablishmentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  ${media.greaterThan("medium")`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 2rem 0;
  `}
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  animation: ${rotate360} 0.8s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid rgba(238, 146, 54, 0.2);
  border-right: 2px solid rgba(238, 146, 54, 0.2);
  border-bottom: 2px solid rgba(238, 146, 54, 0.2);
  border-left: 2px solid rgba(238, 146, 54, 1);
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const Image = styled.img`
  width:70px;
  height:60px;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Nunito";
  font-size: 1.625rem;
  font-weight: normal;
  text-align: center;
  margin: 20px;
  margin-bottom: 1.3rem;
  line-height: 1.3;
`;

const Text = styled(Title)`
  text-align: center;
  font-size: 1.125rem;
  margin-top: 1.5rem;
  font-weight: bold;
  & strong {
    font-weight: bold;
  }
`;

export const ButtonAccept = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.secondary};
  flex: 1;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 11px;
  font-size: 18px;
  padding: 1rem;
  align-self: center;
  width: 226.16px;

  /* &:focus,
  &:hover {
    background-color: #d87e24;
  } */
`;

export default Establishment;
